import { render, staticRenderFns } from "./CAvatar.vue?vue&type=template&id=36903c94&scoped=true&"
import script from "./CAvatar.vue?vue&type=script&lang=js&"
export * from "./CAvatar.vue?vue&type=script&lang=js&"
import style0 from "./CAvatar.vue?vue&type=style&index=0&id=36903c94&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36903c94",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
